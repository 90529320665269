import { twMerge } from "tailwind-merge";

export const Spinner = ({ className }: { className?: string }) => {
  const iconClasses = `col-[2_/_span_1] row-[1_/_span_1] justify-self-center my-auto`;

  return (
    <div
      className={twMerge(
        "animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-blue-600 rounded-full",
        iconClasses,
        className
      )}
      role="status"
      aria-label="loading"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
};
